.notification-root {
  .notification-list {
    padding: 0;
    min-width: 280px;
    line-height: 1;
    max-height: 300px;
    overflow: auto;
    & li {
      padding: 10px;
      &:hover {
        background-color: #e2e2e2;
      }
    }
    & > li + li {
      border-top: 1px solid #cdcdcd;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .notification-root {
    .notification-list {
      max-height: calc(100vh - 54px);
      overflow: auto;
    }
  }
}
