.search-dropdown-root {
  .dropdown {
    background: none !important;
    background-color: transparent !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dropdown:focus {
    background-color: #f1f1f1;
  }
  .dropdown:hover {
    background-color: #f3f3f3;
  }
  .dropdown:active,
  .dropdown.open {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .dropdown:after {
    display: none;
  }
  .dropdown.open:after {
    transform: rotate(-180deg);
  }
  .dropdown.open .list {
    transform: scale(1);
    opacity: 1;
    pointer-events: auto;
  }
  .dropdown.open .option {
    cursor: pointer;
  }
  .dropdown.wide {
    width: 100%;
  }
  .dropdown.wide .list {
    left: 0 !important;
    right: 0 !important;
  }
  .dropdown .list {
    width: inherit;
    box-sizing: border-box;
    transition: all 0.15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity 0.1s linear;
    transform: scale(0.75);
    transform-origin: 50% 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09);
    background-color: #fff;
    border-radius: 6px;
    margin-top: 4px;
    padding: 3px 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    max-height: 306px;
    overflow: auto;
  }
  .dropdown .list:hover .option:not(:hover) {
    background-color: transparent !important;
  }
  .dropdown .option {
    cursor: default;
    font-weight: 400;
    line-height: 40px;
    outline: none;
    padding-left: 10px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s;
  }
  .dropdown .option:hover,
  .dropdown .option:focus {
    background-color: #f6f6f6 !important;
  }
  .dropdown .option.selected {
    font-weight: 600;
  }
  .dropdown .option.selected:focus {
    background: #f6f6f6;
  }

  .search-dropdown-input {
    outline: none;
    border: transparent;
    background: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    font-size: 18px;
  }
  .list-ul {
    padding: 0;
    overflow: auto;
    max-height: 294px;
  }
  .arrow-icon {
    padding: 2%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .search-dropdown-root {
    .search-dropdown-input {
      font-size: inherit;
      width: 100%;
    }
  }
}
