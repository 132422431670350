.footer-root {
  background: #244e77;
  position: relative;
  @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    .footer-widget {
      margin-top: 15px;
    }
    .footer-root {
      text-align: left;
    }
  }

  .footer-content {
    position: relative;
    z-index: 2;
  }

  .footer-widget-heading h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 40px;
    position: relative;
  }
  .footer-widget ul li {
    display: inline-block;
    float: left;
    width: 100%;
    margin-bottom: 12px;
  }
  .footer-widget ul li a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
  }

  .footer-text p span {
    color: #be3850 !important;
  }

  .footer-widget ul li a:hover {
    color: #be3850;
    text-decoration: underline;
  }

  .footer-logo {
    margin-bottom: 30px;
  }
  .footer-logo img {
    max-width: 200px;
  }
  .footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #fff;
    line-height: 28px;
  }
  .footer-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  .footer-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
  }
  .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
  }
  .facebook-bg {
    background: #3b5998;
  }
  .twitter-bg {
    background: #55acee;
  }
  .google-bg {
    background: #dd4b39;
  }

  .copyright-area {
    background: #be3850;
    padding: 25px 0;
  }
  .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #fff;
  }
  .copyright-text p a {
    color: #fff;
  }
  .footer-menu li {
    display: inline-block;
    margin-left: 20px;
  }
  .footer-menu li a {
    font-size: 22px;
    color: #fff;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }
}
