@charset "utf-8";
#google_translate_element select {
  background: #ffffff;
  color: #be3850;
  border: none;
  border-radius: 3px;
  top: 10px;
  padding: 6px 8px;
  width: 52px;
  position: relative;
}
.goog-logo-link {
  display: none !important;
}
.goog-te-gadget {
  color: transparent !important;
}
.goog-te-banner-frame {
  display: none !important;
  height: 0
}

#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
}
.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}

.header-root {
  background: #fff;
  .link {
    display: flex;
    text-decoration: none;
  }
  .mobileSeachbar {
    display: none !important;
  }
  .cart {
    position: relative;
    padding: 7px 10px;
    & i {
      color: #000;
    }
    & span {
      top: 4px;
      font-size: 10px;
      position: absolute;
      right: 0px;
      border-radius: 12px;
      padding: 4px 6px;
    }
    &:hover {
      background-color: #f3f3f3;
      border-radius: 50%;
    }
  }
  /* CSS Document */
  .abu-dropdown .dropdown-toggle::after {
    display: none;
  }

  .dropdown-toggle::after {
    display: none !important;
  }
  .header_right .btn-secondary {
    background-color: transparent !important;
    border-radius: 25px;
    color: #000 !important;
    box-shadow: none !important;
    outline: none !important;
  }
  .btn-menu {
    padding: 4px 13px;
  }
  .dropdown-menu-avatar {
    background: #244e77;
    width: 36px;
    height: 36px;
    border-radius: 27px;
    text-align: center;
    padding: 5px;
    margin-right: 6px;
    color: #fff;
  }
  .abu-dropdown .dropdown-toggle {
    background: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    font-weight: 700;
  }
  .header_right .btn-primary {
    background: #244e77 !important;
    border-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 10px 25px;
  }
  .header_right span {
    color: #be3850;
    // text-transform: uppercase;
  }

  select::-ms-expand {
    display: none;
  }
  .header_right {
    align-items: center;
  }

  .abu-dropdown {
    margin-left: 1.25rem;
    width: 160px;
  }
  .has-search .form-control {
    padding-left: 3rem;
    background: transparent !important;
    border: none;
    box-shadow: none !important;
    outline: none !important;
    font-size: 22px;
  }
  .has-search {
    position: relative;
  }
  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 3.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #244e7787;
    top: 4px;
    font-size: 21px;
    font-weight: 100;
  }
  .searchBar {
    height: 75px;
    display: flex;
    align-items: center;
    border-radius: 44px;
  }
  .searchBar-open {
    margin: 0px 0 10px 0 !important;
  }
  .header_right {
    display: flex;
    text-align: right;
  }

  .mobile_menu i {
    font-size: 28px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 992px) {
    .mobile_menuBg {
      background-color: #fff;
      z-index: 1054 !important;
      height: 56px;
      display: flex;
      align-items: center;
    }
    .loginBox {
      text-align: right;
    }
    .loginBox img {
      width: 20px;
      margin-right: 20px;
    }
    .loginBox img:last-child {
      margin-right: 0px;
    }
    .mobilelogo {
      display: flex;
      align-items: center;
    }
    .userloginLinks h5 a {
      color: #000 !important;
      font-size: 18px;
      text-decoration: none;
    }
    .userloginLinks h5 a span {
      color: #be3850;
    }
    .modal-content {
      top: 47px;
      // background: #f3f4f6;
      border-radius: 0px 0px 0px 15px !important;
      width: 250px !important;
      right: -10px !important;
      margin-right: auto !important;
      left: auto !important;
      float: right;
    }
    .mobileSeachbar {
      text-align: right;
    }
    .mobileSeachbar a {
      color: #be3850;
      font-size: 24px;
    }

    .mobileSeachbar {
      display: block !important;
    }
    .cart {
      padding: 0;
    }
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    .header_left {
      display: block !important;
      text-align: center;
    }
    /*.header_two_logo img{
		margin-bottom: 15px;
	}*/
    .header_two_searchbar .searchBar {
      width: 100%;
      height: 48px;
      font-size: 16px;
      margin-bottom: 15px;
    }
    .header_two .header_right {
      display: none !important;
    }
    .header_right {
      display: block !important;
      text-align: center;
    }
    .form-inline {
      justify-content: center;
    }
    .header_right .dropdown {
      display: inline-block;
    }

    .header_left a img {
      width: 190px;
    }

    .abu-dropdown {
      width: 100% !important;
      margin-left: 0px !important;
      margin-top: 15px;
    }

    .header_right,
    .header_left,
    .abu-dropdown {
      text-align: center !important;
    }
    .header_right .btn-primary {
      margin-top: 10px;
    }

    ._43mycv {
      display: none;
    }

    .down_icon {
      top: 14px !important;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .header_right,
    .header_left,
    .abu-dropdown {
      text-align: center !important;
    }
    .header_left a img {
      width: 70%;
    }
    .abu-dropdown {
      margin-left: 0rem;
      width: 70%;
    }
  }

  .abu-dropdown {
    font-weight: 700;
    position: relative;
    font-size: 12px;
  }
  // select {
  //   // display: none;
  // }
  .dropdown {
    background: none !important;
    background-color: transparent !important;
    cursor: pointer;
  }
  .dropdown:focus {
    background-color: #f1f1f1;
  }
  .dropdown:hover {
    background-color: #f3f3f3;
  }
  .dropdown:active,
  .dropdown.open {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .dropdown:after {
    display: none;
  }
  .dropdown.open:after {
    transform: rotate(-180deg);
  }
  .dropdown.open .list {
    transform: scale(1);
    opacity: 1;
    pointer-events: auto;
  }
  .dropdown.open .option {
    cursor: pointer;
  }
  .dropdown.wide {
    width: 100%;
  }
  .dropdown.wide .list {
    left: 0 !important;
    right: 0 !important;
  }
  .dropdown .list {
    box-sizing: border-box;
    transition: all 0.15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity 0.1s linear;
    transform: scale(0.75);
    transform-origin: 50% 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09);
    background-color: #fff;
    border-radius: 6px;
    margin-top: 4px;
    padding: 3px 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 999;
  }
  .dropdown .list:hover .option:not(:hover) {
    background-color: transparent !important;
  }
  .dropdown .option {
    position: relative;
    cursor: default;
    font-weight: 400;
    line-height: 1rem;
    outline: none;
    padding-top: 4px;
    padding-left: 18px;
    padding-right: 29px;
    padding-bottom: 4px;
    text-align: left;
    transition: all 0.2s;
    &::after {
      content: '';
      border-bottom: 1px solid #ececec;
      width: 100%;
      left: 0;
      bottom: 0;
      position: absolute;
    }
  }
  .dropdown .option:hover,
  .dropdown .option:focus {
    background-color: #f6f6f6 !important;
  }
  .dropdown .option.selected {
    font-weight: 600;
  }
  .dropdown .option.selected:focus {
    background: #f6f6f6;
  }

  .down_icon {
    position: absolute;
    right: 15px;
    top: 35px;
  }

  ._43mycv {
    -ms-grid-row-align: center !important;
    -ms-flex-item-align: center !important;
    -webkit-align-self: center !important;
    align-self: center !important;
    border-right: 1px solid #dddddd !important;
    -webkit-flex: 0 0 0px !important;
    -ms-flex: 0 0 0px !important;
    flex: 0 0 0px !important;
    height: 32px !important;
  }
  .abu-dropdown .down_icon {
    top: 9px !important;
    right: 5px;
    width: 12px;
  }
  .header_left {
    display: flex;
    align-items: center;
    width: 32vw;
  }

  /* header fixed */
  .header_two {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    margin: auto;
    border-radius: 0px;
    z-index: 1054;
    left: 0;
    right: 0;
  }
  .header_two_show {
    display: block;
    position: fixed;
    top: 0;
  }
  .header_two_searchbar .searchBar {
    background: #ced4da42;
  }
  // .header_two_logo {
  //   /*	text-align: center;*/
  // }

  .header_two_searchbar .searchBar {
    width: 300px;
    height: 48px;
    font-size: 16px;
    margin: auto;
  }
  .navbar-brand i {
    color: #be3850;
  }
  .notification-badge {
    position: absolute;
    right: -9px;
    font-size: 10px;
    border-radius: 12px;
    padding: 4px 6px;
  }
  .notification-icon-container {
    position: relative;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .header-root {
    height: 56px;
    .header_two_searchbar .searchBar {
      width: 100%;
      height: 48px;
      font-size: 16px;
      margin-bottom: 15px;
    }
    .header_two .header_right {
      display: none !important;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 992px) {
  .header-root {
    height: 56px;
    .header_two_searchbar {
      display: none;
    }
    .icon-badge {
      position: absolute;
      top: -4px !important;
      right: -4px !important;
      padding: 3px 5px !important;
      font-size: 8px !important;
      border-radius: 8px !important;
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 992px) {
  .header-root {
    .header_left a img {
      width: 375px;
    }
    .abu-dropdown {
      margin-left: 0rem;
      width: 70%;
    }

    .down_icon {
      top: 20px !important;
    }
    .uerMenu .modal-body {
      top: 25px;
      background: #f3f4f6;
      border-radius: 0px 0px 0px 15px !important;
      width: 250px !important;
      right: -145px !important;
      margin-right: auto !important;
      left: auto !important;
      float: right;
    }
  }
}
