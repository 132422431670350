.loadingDiv {
  background: #fff;
  border-radius: 15px;
  position: relative;
  padding: 3%;
  display: flex;

  & div.loadingImg {
    background: #f1f1f1;
    border-radius: 15px;
    margin-right: 3%;
    height: 173px;
    flex-basis: 24%;
  }
  & div.loadingContent {
    flex-basis: 70%;
    & div {
      background: #f1f1f1;
      border-radius: 5px;
      height: 24px;
      margin-bottom: 5px;
    }
    .loadingTitle {
      width: 100%;
    }
    .loadingSecondaryLine {
      width: 50%;
    }
    .loadingThirdLine {
      width: 25%;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .loadingImg {
    height: 23.43vw !important;
    flex-basis: 28% !important;
  }
  .loadingContent > div {
    height: 14px !important;
  }
}
