//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}
