.sidebar-root {
  position: relative;
  height: 100%;
  .rfqMenu {
    position: relative;
    background-color: #244e77;
    min-height: 100%;
  }
  .rfqMenu .nav-pills .nav-link {
    padding: 20px;
    color: #000;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .rfqMenu .nav-pills .nav-link:hover {
    background-color: #be3850;
    border-radius: 0px;
    text-decoration: none;
  }
  .rfqMenu .nav-pills .nav-link.active {
    color: #fff;
    background-color: #be3850;
    border-radius: 0px;
  }
  .dropdown-item {
    padding: 15px 20px;
    background: #244e77;
    border-radius: 10px 0px 0px 10px;
  }
  .dropdown-menu-avatar {
    background: #be3850;
    width: 36px;
    height: 36px;
    border-radius: 27px;
    text-align: center;
    padding: 5px;
    margin-right: 6px;
    color: #fff;
  }
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 3;
    top: 0px;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s;
    box-shadow: #1212121a -2px 2px 6px;
  }

  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    color: #fff;
    display: block;
    transition: 0.3s;
  }
  .sidenav a:hover,
  .offcanvas a:focus {
    color: #ffff;
  }
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
}
@media screen and (max-height: 450px) {
  .sidebar-root {
    // .sidenav {
    //   // padding-top: 5px;
    // }
    .sidenav a {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 600px) {
  .sidebar-root {
    position: absolute;
    .rfqMenu {
      background-color: transparent;
    }
    .logout-container {
      position: absolute;
      background: #fff;
      bottom: 0;
      width: 100%;
      padding-left: 0;
    }
  }
}
