.auth-layout-root {
  padding: 100px;
  height: 100%;
  min-height: 100vh;
  background: #f2f5fa;
  background: url(../../assets/images/signbg.png) left bottom no-repeat #f2f5fa;

  .add_your_business {
    box-shadow: 0px 3px 12px #0000000d;
    border-radius: 3px;
    background: #fff;
    margin: 3rem;
    padding: 3rem;
  }
  .add_your_business_Tittle h2 {
    font-size: 30px;
    color: #be3850;
    font-weight: 700;
  }
  .add_your_business_Tittle {
    margin-bottom: 50px;
  }
  .add_your_business_details {
    width: 75%;
    margin: auto;
  }
  .slideLabel {
    width: 53px;
    height: 53px;
    border: 1px solid #d4d4d4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    position: relative;
    margin: auto;
    z-index: 10;
    background: #fff;
    cursor: pointer;
    color: #888888;
  }
  .slideLabel:after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #d4d4d4;
    width: 145px;
    height: 2px;
    right: -147px;
    z-index: 1;
  }
  .slideLabellast:after {
    display: none;
  }
  .slidebar .active {
    border: 2px solid #0d92d4;
    color: #0d92d4;
  }
  .businessBox {
    width: 50%;
    border-radius: 10px;
    margin: auto;
    background: #f5f6fa;
    padding: 25px;
    margin-top: 35px;
  }
  .businessBox h2 {
    font-size: 30px;
    color: #244e77;
    margin-bottom: 0px;
    font-weight: 700;
  }
  .businessBox p {
    font-size: 18px;
    color: #050931;
  }
  .businessBox label {
    color: #050931;
    font-size: 18px;
  }
  .businessBox .form-control {
    border-color: #e3e6f2 !important;
    padding: 12px;
    border-radius: 12px;
    background: #fff;
    box-shadow: none !important;
    outline: none !important;
    font-size: 18px;
    position: relative;
  }
  .businessBox .form-control .down_icon {
    position: absolute;
    right: 15px;
    top: 25px;
  }
  .businessBox .form-control::placeholder {
    color: #050931 !important;
    opacity: 0.9;
  }
  .businessBox .btn-danger {
    background-color: #be3850;
    border-color: transparent;
    box-shadow: none !important;
    outline: none !important;
    padding: 12px;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    margin-top: 30px;
    border-radius: 12px;
  }
  .slidebar .row {
    justify-content: center;
  }
  .businessBox a {
    text-decoration: none !important;
  }
  .skip {
    color: #050931 !important;
    text-decoration: none;
    font-size: 18px;
  }
  .formcomplate {
    color: #fff;
    background-color: #0d92d4;
    border-color: #0d92d4;
  }
  .formcomplate:after {
    border-color: #0d92d4 !important;
  }
  .skip-btn {
    margin-top: 15px;
  }
  .working_hours .businessBox {
    width: 65%;
  }
  .working_days .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  .working_days .form-group label {
    position: relative;
    cursor: pointer;
    margin-bottom: 17px;
  }
  .working_days .form-group label:before {
    content: '';
    -webkit-appearance: none;
    border: 2px solid #e3e6f2;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    background: #ffffff;
    border-radius: 9px;
  }
  .working_days .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #be3850;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .working_days,
  .working_time {
    margin-top: 15px;
  }
  .working_time .form-control {
    padding: 0.475rem 0.75rem;
    height: auto !important;
    font-weight: 700;
  }
  .working_hours .businessBox .btn-danger {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .businessBox .form-control {
    height: auto !important;
  }
  .file-upload {
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: -20px;
  }
  .file-upload-btn {
    width: 100%;
    margin: 0;
    color: #fff;
    background: #1fb264;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #15824b;
    transition: all 0.2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
  }
  .file-upload-btn:hover {
    background: #1aa059;
    color: #ffffff;
    transition: all 0.2s ease;
    cursor: pointer;
  }
  .file-upload-btn:active {
    border: 0;
    transition: all 0.2s ease;
  }
  .file-upload-content {
    display: none;
    text-align: center;
  }
  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
    z-index: 10000;
  }
  .image-upload-wrap {
    margin-top: 20px;
    border: 1px dashed #0d92d4;
    position: relative;
    border-radius: 10px;
    height: 174px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .drag-text {
    text-align: center;
  }
  .drag-text h3 {
    font-weight: 300;
    color: #050931;
    font-size: 16px;
    opacity: 0.3;
    margin-top: 15px;
  }
  .file-upload-image {
    max-height: 200px;
    max-width: 200px;
    margin: auto;
    padding: 20px;
  }
  .remove-image {
    width: 200px;
    margin: 0;
    color: #fff;
    background: #cd4535;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #b02818;
    transition: all 0.2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
  }
  .remove-image:hover {
    background: #c13b2a;
    color: #ffffff;
    transition: all 0.2s ease;
    cursor: pointer;
  }
  .remove-image:active {
    border: 0;
    transition: all 0.2s ease;
  }
  .registertittle {
    width: 315px;
    margin: auto;
    text-align: left;
  }
  .signupform h2 {
    font-size: 30px;
    color: #707070;
    font-weight: bold;
  }
  .signupform .form-control {
    padding: 20px;
    background-color: #e9eff6;
    border-color: #e9eff6;
    border-radius: 20px;
    font-size: 16px;
    color: #707070;
  }
  .signupform .form-control::placeholder {
    color: #707070;
  }
  .signupform .btn-primary {
    background-color: #244e77;
    border-color: #244e77;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 20px;
  }
  .orlabel {
    margin-top: 15px;
    position: relative;
    font-size: 19px;
    color: #a0aab6;
  }
  .orlabel:before {
    width: 100px;
    border-bottom: 1px solid #a0aab6;
    content: '';
    position: absolute;
    left: 0px;
    top: 12px;
  }
  .orlabel:after {
    width: 100px;
    border-bottom: 1px solid #a0aab6;
    content: '';
    position: absolute;
    right: 0px;
    top: 12px;
  }
  .socielBTN .btn-outline-light {
    border: 1px solid #a0aab6 !important;
    padding: 20px;
    background-color: transparent;
    width: 120px;
    border-radius: 20px;
  }
  .socielBTN .btn-outline-light img {
    width: 35px;
  }
  .socielBTN .btn-outline-light:hover {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .socielBTN {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .registertittle h1 {
    font-size: 50px;
    color: #263238;
    font-weight: bold;
  }
  .registertittle p {
    font-size: 20px;
    color: #000000;
    font-weight: bold;
  }
  .registertittle p a {
    color: #be3850 !important;
  }

  .input-field-error {
    border-color: #be3850 !important;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(192 54 79 / 25%);
    }
  }
  ::-webkit-input-placeholder {
    color: #7b7678;
  }
  ::placeholder {
    color: #7b7678;
  }
  .countrycode-dd {
    display: flex;
    background: #e9eff6;
    height: 64px;
    border-radius: 26px;
    & > div > div > input {
      padding: 21px 14px;
    }
  }
  .form-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1001;
  }
  .form-loader-backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1000;
    opacity: 0.2;
    background: black;
  }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1350px) {
  .auth-layout-root {
    .main_searchBar,
    .app_store,
    .whymoto {
      width: 100%;
    }
    .getquote {
      display: block !important;
      text-align: center;
    }
    .custom-select {
      width: 225px !important;
    }
    .spareDetail .btn-danger,
    .spareDetail .btn-primary {
      height: 60px;
      font-size: 14px;
    }
    .spare-img {
      height: 145px;
    }
    .sparepart {
      margin-right: 30px;
      width: 200px;
      height: 200px;
      display: inline-block;
    }
    .list_bg {
      background-image: url(../../assets/images/list.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      padding: 15px;
      margin-bottom: 50px;
      background-position: right;
      text-align: right;
    }
    /*Register Business Pages*/
    .add_your_business_details {
      width: 100%;
    }
    .add_your_business {
      margin: 3rem 0px;
      padding: 3rem 0px;
    }

    .businessBox {
      width: 75%;
    }
    .slideLabel:after {
      position: absolute;
      content: '';
      border-bottom: 1px solid #d4d4d4;
      width: 145px;
      height: 2px;
      right: -145px;
      z-index: 1;
    }
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .auth-layout-root {
    padding: 15px;
    .registertittle h1 {
      font-size: 30px;
      color: #263238;
      font-weight: bold;
    }
    .orlabel:before {
      width: 50px;
    }
    .orlabel:after {
      width: 50px;
    }
  }
}
